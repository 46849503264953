import React, { Component } from "react";
import styled from "styled-components";
import { withPage } from "../../Page";
import * as Widget2 from "../../Components/Widget2";

const AboutUsItems = [
  {
    title: "經營理念",
    image: "photo05.png",
    description:
      "誕生於2015年, 是由一群專業的菁英團 隊所組成，擁有具專業的護理長與護理師團隊並建立一套完善的 照護制度，提供24小時的全天候服務、產前產後諮詢、育兒諮詢專線、 多元化課程學習、專任醫師",
    reverse: false
  },
  {
    title: "經營宗旨",
    image: "photo06.png",
    description:
      "誕生於2015年, 是由一群專業的菁英團 隊所組成，擁有具專業的護理長與護理師團隊並建立一套完善的 照護制度，提供24小時的全天候服務、產前產後諮詢、育兒諮詢專線、 多元化課程學習、專任醫師",
    reverse: true
  }
];

class AboutUsPage extends Component {
  render() {
    return (
      <Wrapper>
        <Widget2.Banner img="/images/banner02.png" css="margin-bottom: 90px;">
          <div className="mist">
            <div className="title">關於我們</div>
            <div className="slogan">青田悅產後護理之家100分！</div>
          </div>
        </Widget2.Banner>

        <Container>
          {AboutUsItems.map((item, i) => (
            <AboutUsItem
              key={"about-us-item" + i}
              img={`/images/${item.image}`}
              css="margin-bottom: 80px;"
              reverse={item.reverse}
            >
              <div className="text">
                <div className="title">{item.title}</div>
                <div className="description">{item.description}</div>
              </div>
              <div className="img" />
            </AboutUsItem>
          ))}

          <AboutUsItem img={"/images/photo16.png"} largeImg={true}>
            <div className="text">
              <div className="title">交通指南</div>
              <div className="description">
                麥帥一橋西往東走南京東路六段，至舊宗路左轉，直行舊宗路至行善路右轉，至石潭路口即抵達機構。民權大橋西往東走民權東路六段，至行愛路右轉，直行行愛路至行善路左轉，至石潭路口即抵達機構。
              </div>
            </div>
            <div className="img" />
          </AboutUsItem>
        </Container>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  padding-top: 100px;
`;

const Container = styled.div`
  margin: 0 auto;
  max-width: 1181px;
  padding: 20px 20px 82px;
`;

const AboutUsItem = styled.div`
  display: flex;
  flex-direction: ${props => (props.reverse ? "row-reverse" : "row")};
  justify-content: space-between;
  align-items: center;

  & > .text {
    margin-right: ${props => (props.reverse ? 0 : 20)}px;
    max-width: ${props => (props.largeImg ? 380 : 580)}px;
    width: 100%;

    & > .title {
      margin-bottom: 20px;
      font-size: 30px;
      color: #71c6d0;
      letter-spacing: 3px;

      @media screen and (max-width: 600px) {
        font-size: 24px;
      }
    }

    & > .description {
      font-size: ${props => (props.largeImg ? 16 : 24)}px;
      color: #101d1f;
      letter-spacing: 2.4px;

      @media screen and (max-width: 600px) {
        font-size: 18px;
      }
    }

    @media screen and (max-width: 768px) {
      max-width: ${props => (props.largeImg ? "100%" : "580px")};
      margin-bottom: ${props => (props.largeImg ? 20 : 0)}px;
    }

    @media screen and (max-width: 414px) {
      margin-right: 0px;
      margin-bottom: 20px;
    }
  }

  & > .img {
    margin-right: ${props => (props.reverse ? 20 : 0)}px;
    border: ${props => (props.largeImg ? "1px solid lightgray" : "0px")};
    border-radius: ${props => (props.largeImg ? 0 : 20)}px;
    max-width: ${props => (props.largeImg ? 780 : 580)}px;
    min-height: ${props => (props.largeImg ? 425 : 400)}px;
    width: 100%;
    height: 100%;
    background-image: url(${props => props.img});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (max-width: 414px) {
      margin-right: 0px;
    }
  }

  @media screen and (max-width: 768px) {
    flex-wrap: ${props => (props.largeImg ? "wrap" : "nowrap")};
  }

  @media screen and (max-width: 414px) {
    flex-wrap: wrap;
  }

  ${props => props.css};
`;

export default withPage(AboutUsPage);
